<template>
    <div>
        <v-select
                v-model="getDocumentType"
                :options="options"
                :label="label"
                :disabled="disabled"
                :placeholder="placeholder"
                :multiple="multiple">
        </v-select>
    </div>
</template>

<script>
  import vSelect from 'vue-select'
  import "vue-select/dist/vue-select.css";
  import {mapState} from "vuex";

  export default {
    components: {
      vSelect
    },
    props: {
      placeholder: String,
      value: Object,
      label: String,
      disabled: Boolean,
      multiple: {type: Boolean, default: false},
      options: {type: Array, default: () => []}
    },
    computed: {
      ...mapState({
        document: state => state.document.document
      }),
      getDocumentType: {
        get: function() {
          if(this.document == null) {
            return null
          }
          if (this.document) {
            let type = this.document.documentType
            if (type == null || type.name === '' || type.name === null) {
              return null
            }
            return type;
          }
          return null
        },
        set: function(value) {
          this.$emit('handleDoxSelect', value)
        }
      }
    },
    data() {
      return {
        selected: {}
      }
    },
    methods: {
      handleSelection(value) {
        this.selected = value
        this.$emit('handleDoxSelect', value)
      }
    }
  }
</script>

<style scoped>

</style>
