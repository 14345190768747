<template>
  <div>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <b-img class="w-25 mb-3"
                 fluid
                 :src="imgUrl"
                 alt="Not authorized page"
          />
          <h2 class="mb-2">
            {{ $t('document.noDocumentExists') }} ! &#128209;
          </h2>
          <p class="mb-2">
            {{ $t('document.emptyListInformation') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {BButton, BImg, BModal} from "bootstrap-vue";
import CreateModal from '../Create/index';
import Ripple from 'vue-ripple-directive'
import SelectWorkspace from '../Create/CreateBlankDocumentModal'
import {mapState} from "vuex";

export default {
  name: "Empty",
  components: {
    BImg
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      submitting: false,
      modalShow: false,
      emptyImg: require('@/assets/images/illustration/empty.svg'),
      selectedTemplateId: null
    }
  },
  computed: {
    imgUrl() {
      return this.emptyImg
    },
    ...mapState({
      workspaces: state => state.workspaces.myWorkspaces
    }),
  },
  methods: {

    cleanStates() {
      this.$store.dispatch('document/cleanVariables')
      this.$store.dispatch('document/refreshDocument')
    },
  }
}
</script>

<style scoped>

</style>
