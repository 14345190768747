<template>
    <div>
        <loading v-if="loading"></loading>
        <div v-else>
            <Empty v-if="!isFilterEnabled && documentList.length === 0"/>

            <DocumentList v-else
                          :reff="refDocumentListTable"
                          :is-busy="loading"
                          :per-page="perPage"
                          :per-page-options="perPageOptions"
                          :documents="documentList"
                          :total-documents="totalDocuments"
                          :table-columns="tableColumns"
                          :data-meta="dataMeta"
                          :current-page="currentPage"
                          @toggle-filter="toggleFilter"
                          :resolve-document-type-variant="resolveDocumentTypeVariant"
                          :resolve-document-status-variant-and-icon="resolveDocumentStatusVariantAndIcon">
            </DocumentList>
        </div>
    </div>
</template>

<script>
  import Empty from "@/views/Documents/List/Empty";
  import DocumentList from "@/views/Documents/List/DocumentList"
  import {mapState} from "vuex";
  import {avatarText} from "@core/utils/filter";
  import useDocumentList from "@/views/Documents/List/useDocumentList";
  import Loading from "@/views/components/Loading/Loading";

  export default {
    name: "List",
    components: {Loading, Empty, DocumentList},
    computed: {
      ...mapState({
        documentList: state => state.document.myDocumentList,
        loading: state => state.document.listLoading
      }),
    },
    data() {
      return {
        isFilterEnabled: false
      }
    },
    setup() {
      const statusOptions = [
        'Downloaded',
        'Draft',
        'Paid',
        'Partial Payment',
        'Past Due',
      ]

      const {
        fetchMyDocuments,
        tableColumns,
        perPage,
        currentPage,
        totalDocuments,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDocumentListTable,
        statusFilter,
        reFetchData,
        resolveDocumentStatusVariantAndIcon,
        resolveDocumentTypeVariant,
      } = useDocumentList()

      return {
        fetchMyDocuments,
        tableColumns,
        perPage,
        currentPage,
        totalDocuments,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDocumentListTable,
        statusFilter,
        reFetchData,
        statusOptions,
        avatarText,
        resolveDocumentStatusVariantAndIcon,
        resolveDocumentTypeVariant,
      }
    },
    mounted() {
      this.fetchMyDocuments()
    },
    methods: {
      toggleFilter(event) {
        this.isFilterEnabled = event
      }
    }
  }
</script>

<style scoped>

</style>
