import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {uuid} from "vue-uuid";

export default function useDocumentList() {
  // Use toast
  const toast = useToast()

  const refDocumentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {key: 'code', label: '#', sortable: true},
    {key: 'title', sortable: true},
    {key: 'status', sortable: true},
    {key: 'approveRatio', sortable: true},
    {key: 'documentType', sortable: true, formatter: val => `$${val}`},
    {key: 'updatedAt', sortable: true},
  ]
  const perPage = ref(20)
  const from = ref(0)
  const to = ref(0)
  const totalDocuments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [20, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value ? refDocumentListTable.value.localItems.length : 0
    return {
      from: from.value,
      to: to.value,
      of: totalDocuments.value,
    }
  })

  const reFetchData = () => {
    refDocumentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    reFetchData()
  })

  const fetchMyDocuments = (ctx, callback) => {
    store
        .dispatch('document/loadMyDocuments', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
        })
        .then(response => {
          const { results, total, start, end, page, size } = response
          from.value = start
          to.value = end
          totalDocuments.value = total
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching documents' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDocumentStatusVariantAndIcon = status => {
    if (status === 'INITIAL') return {variant: 'info', icon: 'FileIcon'}
    if (status === 'DRAFT') return {variant: 'secondary', icon: 'EditIcon'}
    if (status === 'APPROVED') return {variant: 'success', icon: 'CheckIcon'}
    if (status === 'WAITING_TO_APPROVE') return {variant: 'warning', icon: 'ClockIcon'}
    if (status === 'WAITING_TO_SIGN') return {variant: 'danger', icon: 'PenToolIcon'}
    if (status === 'SIGNED') return {variant: 'success', icon: 'ArrowDownCircleIcon'}
    if (status === 'CANCELLED') return {variant: 'danger', icon: 'XIcon'}
    if (status === 'ARCHIVED') return {variant: 'dark', icon: 'ArchiveIcon'}
    return {variant: 'dark', icon: 'HelpCircleIcon'}
  }

  const resolveDocumentTypeVariant = type => {
    if (type === '' || type === null) return 'dark'
    return 'info'
  }

  return {
    fetchMyDocuments,
    tableColumns,
    perPage,
    currentPage,
    totalDocuments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,

    statusFilter,

    resolveDocumentStatusVariantAndIcon,
    resolveDocumentTypeVariant,

    reFetchData,
  }
}
