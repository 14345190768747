<template>
    <div>
        <b-modal
                ref="modal"
                hide-footer
                no-close-on-backdrop
                :title="$t('document.createNew')">
            <b-form @submit.prevent>
                <b-row align-h="start">
                    <!-- document title -->
                    <b-col cols="12">
                        <b-form-group
                                :label="$t('document.documentTitleInformation')"
                                label-for="fh-doc-name">
                            <b-input-group class="input-group-merge large-input">
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="TypeIcon" size="22"
                                                  :class="'text-warning stroke-current '"/>
                                </b-input-group-prepend>
                                <b-form-input
                                        class="large-input"
                                        id="fh-doc-name"
                                        :placeholder="$t('document.documentTitlePlaceholder')"
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- type select -->
                    <b-col cols="12">
                        <b-form-group
                                :label="$t('document.documentTypeInformation')"
                                label-for="fh-doc-type">
                            <DoxSelect :placeholder="$t('document.documentTypePlaceholder')"/>
                        </b-form-group>
                    </b-col>

                    <!-- start date -->
                    <b-col cols="12">
                        <b-form-group
                                :label="$t('document.startDateInformation')"
                                label-for="fh-start-date">
                            <b-form-datepicker
                                    id="fh-start-date"
                                    size="lg"
                                    class="large-input"
                                    local="en"
                                    :placeholder="$t('document.startDatePlaceholder')"
                            />
                          <b-form-invalid-feedback :state="validation">
                            Your password must be 8-20 characters long.
                          </b-form-invalid-feedback>
                          <b-form-valid-feedback :state="validation">
                            Looks Good.
                          </b-form-valid-feedback>
                        </b-form-group>
                    </b-col>

                    <!-- end date -->
                    <b-col cols="12">
                        <b-form-group
                                :label="$t('document.endDateInformation')"
                                label-for="fh-end-date">
                            <b-form-datepicker
                                    id="fh-end-date"
                                    size="lg"
                                    class="large-input"
                                    local="en"
                                    :placeholder="$t('document.endDatePlaceholder')"
                            />
                            <b-form-invalid-feedback :state="validation">
                                Your password must be 8-20 characters long.
                            </b-form-invalid-feedback>
                            <b-form-valid-feedback :state="validation">
                                Looks Good.
                            </b-form-valid-feedback>
                        </b-form-group>
                    </b-col>


                    <!-- occupation -->
                    <!-- type select -->
                    <b-col cols="12">
                        <b-form-group
                                :label="$t('document.partiesInformation')"
                                label-for="fh-doc-participants">
                            <DoxSelect :placeholder="$t('document.partiesPlaceholder')"
                                       :multiple="true"/>
                        </b-form-group>
                    </b-col>


                    <!-- submit and reset -->
                    <b-col cols="12">
                        <br/>
                        <b-row align-h="end" class="px-1">
                            <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                            >
                                {{$t('document.createAndContinue')}}
                            </b-button>
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
  import {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormDatepicker
  } from 'bootstrap-vue'
  import Ripple from "vue-ripple-directive";
  import DoxSelect from "@/views/components/Select/DoxSelect";

  export default {
    name: "index",
    components: {
      BModal,
      BRow,
      BCol,
      BFormGroup,
      BInputGroup,
      BInputGroupPrepend,
      BFormInput,
      BForm,
      BButton,
      BFormInvalidFeedback,
      BFormValidFeedback,
      BFormDatepicker,
      DoxSelect
    },
    directives: {
      Ripple,
    },
    props: {},
    computed: {
      validation() {
        return this.userId.length > 4 && this.userId.length < 13
      },
      validationOccupation() {
        return this.occupationID.length > 1
      },
    },
    data: () => ({
      options: ['Devloper', 'Manager', 'CEO', 'CTO', 'Full Stack Devloper'],
      userId: '',
      occupationID: ''
    })
  }
</script>

<style scoped>

</style>