<template>
  <!-- Table Container Card -->
  <div class="row d-flex flex-column px-2 table-resize">
    <div class="mb-1">
      <!-- Table Top -->
      <div class="row d-flex align-items-center justify-content-between hide-mobile">
        <!-- Per Page -->
        <div>
          <!--          <b-button style=" background-color:  rgba(115, 103, 240, 0.12); width: 150px"-->
          <!--                    v-ripple.400="'rgba(115, 103, 240, 0.12)'"-->
          <!--                    variant="secondary"-->
          <!--                    class="mr-1 elevated-btn"-->
          <!--                    @click="selectWorkspace">-->
          <!--            <div class="flex align-items-start justify-content-start align-content-start w-100">-->
          <!--              <feather-icon icon="PlusIcon" size="18"/>-->
          <!--              <span class="font-weight-bold">Yeni oluştur</span>-->
          <!--            </div>-->
          <!--          </b-button>-->
          <b-button v-if="!isFilteringAreaOpen" style=" background-color: rgba(98, 98, 98, 0.104)"
                    v-ripple.400="'rgba(98, 98, 98, 0.104)'"
                    variant="flat-black"
                    class="rounded-circle"
                    @click="isFilteringAreaOpen = !isFilteringAreaOpen">
            <div class="icon-wrapper-large">
              <feather-icon icon="FilterIcon" size="18"/>
            </div>
          </b-button>
          <b-button v-else
                    v-b-popover.hover.top="$t('document.clearFilter')"
                    variant="danger"
                    class="rounded-circle mr-1"
                    @click="cleanFilterParameters">
            <div class="icon-wrapper-large">
              <feather-icon icon="XIcon" size="18"/>
            </div>
          </b-button>
        </div>
        <div class="ml-auto justify-content-end">
          <v-select v-model="perPage"
                    v-on:option:selected="updateDocumentQuery"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector " style="width: 100px;"/>
        </div>
      </div>
      <transition name="fade">
        <div class="row mt-1" v-show="isFilteringAreaOpen">
          <div class="col-sm-12 d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <b-form-input v-model="labelSearchQueryParam"
                          v-on:change="updateDocumentQuery"
                          v-on:input="handleFormInput"
                          class="mr-1"
                          :placeholder="$t('filter.label')"/>


            <b-form-input v-model="titleSearchQueryParam"
                          v-on:change="updateDocumentQuery"
                          v-on:input="handleFormInput"
                          class="mr-1"
                          :placeholder="$t('filter.title')"/>


            <b-form-input v-model="codeSearchQueryParam"
                          v-on:change="updateDocumentQuery"
                          v-on:input="handleFormInput"
                          class="mr-1"
                          :placeholder="$t('filter.code')"
                          v-uppercase/>


            <flat-pickr v-model="dateRangeQueryParam"
                        v-on:on-close="filterByDate($event)"
                        :placeholder="$t('filter.dateRange')"
                        class="form-control invoice-filter-select mr-1"
                        :config="{ mode: 'range'}"/>


            <v-select v-model="documentStatusQueryParam"
                      v-on:input="updateDocumentQuery"
                      :options="documentStatuses"
                      label="displayText"
                      class="invoice-filter-select mr-1"
                      :placeholder="$t('select.status')">
              <template #selected-option="{ displayText }">
                      <span class="text-truncate overflow-hidden">
                        {{ displayText }}
                      </span>
              </template>
            </v-select>


            <v-select v-model="documentTypeQueryParam"
                      v-on:input="updateDocumentQuery"
                      :options="documentTypes"
                      label="displayText"
                      class="invoice-filter-select mr-1"
                      :placeholder="$t('select.documentType')">
              <template #selected-option="{ displayText }">
                      <span class="text-truncate overflow-hidden">
                        {{ displayText }}
                      </span>
              </template>
            </v-select>


          </div>
        </div>
      </transition>
    </div>
    <div class="row">
      <div class=" w-100">
        <b-table :ref="reff"
                 responsive
                 selectable
                 select-mode="single"
                 @row-selected="onRowSelected"
                 :items="documents"
                 :small="false"
                 :fields="tableColumns"
                 primary-key="id"
                 :hover="true"
                 :head-variant="null"
                 :sort-by.sync="sortBy"
                 :busy="isBusy"
                 show-empty
                 :empty-text="$t('document.noDocumentFoundForCriteria')"
                 :sort-desc.sync="isSortDirDesc"
                 class="position-relative p-0 m-0 w-100 documents-table">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('loading') }}</strong>
            </div>
          </template>
          <!-- Table Headers -->
          <template #head(code)>
            <feather-icon icon="HashIcon"
                          class="mx-auto"/>
          </template>
          <template #head(title)>
            <span>{{ $t('table.title') }}</span>
          </template>
          <template #head(status)>
            <span>{{ $t('table.status') }}</span>
          </template>
          <template #head(approveRatio)>
            <span>{{ $t('table.approveRatio') }}</span>
          </template>
          <template #head(documentType)>
            <span>{{ $t('table.documentType') }}</span>
          </template>
          <template #head(updatedAt)>
            <span>{{ $t('table.updatedAt') }}</span>
          </template>

          <!-- Table content cells -->
          <template #cell(code)="data">
           <span class="p-25 rounded font-weight-bold" style="background-color: rgba(9, 30, 66, 0.04);">
             #{{ data.value }}
           </span>
          </template>

          <template #cell(title)="data">
            <div>
              <div class="row">
                <span class="font-weight-bolder text-black">{{ data.value }}</span>
              </div>
              <div class="row">
                <span class="text-black-50 font-weight-bolder font-small-3">{{ data.item.workspace }}</span>
              </div>
            </div>
          </template>

          <template #cell(documentType)="data">
            <div
                class="font-weight-bold">
              <b-badge pill
                       :variant="`light-${resolveDocumentTypeVariant(data.item.documentType.displayText)}`"
                       class="text-capitalize">
                {{ $t('documentTypes.'.concat(data.item.documentType.name)) }}
              </b-badge>
            </div>
          </template>

          <template #cell(status)="data">
            <div
                class="font-weight-bold">
              <b-avatar :id="`document-row-${data.item.id}`"
                        size="32"
                        :variant="`light-${resolveDocumentStatusVariantAndIcon(data.item.lifecycleDetail.status.name).variant}`">
                <feather-icon :icon="resolveDocumentStatusVariantAndIcon(data.item.lifecycleDetail.status.name).icon"/>
              </b-avatar>
              <b-tooltip :target="`document-row-${data.item.id}`"
                         placement="top">
                <p class="mb-0">
                  {{ $t('documentStates.'.concat(data.item.lifecycleDetail.status.name)) }}
                </p>
              </b-tooltip>
            </div>
          </template>

          <template #cell(approveRatio)="data">
            <div
                class="font-weight-bold">
              <div class="d-flex align-items-center">
                <vue-apex-charts type="radialBar"
                                 height="30"
                                 width="30"
                                 :options="options"
                                 :series="[data.item.approveRatio]"/>
                <span class="font-weight-bold text-body-heading mr-1 ml-1">{{ data.item.approveRatio }}%</span>
              </div>
            </div>
          </template>

          <template #cell(updatedAt)="data">
            <div
                class="font-weight-bold">
          <span class="text-nowrap">
            <span v-b-tooltip.hover.bottom="getFormattedDate(data.item.updatedAt)">
              {{
                getFromNow(data.item.updatedAt)
              }}
            </span>
          </span>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12"
               sm="6"
               class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            {{
              $tc('itemCountOnTable', dataMeta.from, dataMeta.to, {
                from: dataMeta.from,
                to: dataMeta.to
              })
            }}
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12"
               sm="6"
               class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-on:change="handlePageUpdate"
                        v-model="currentPage"
                        :total-rows="totalDocuments"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <populate-variables-modal/>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
  VBPopover,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DateUtil from '@/services/DateUtil'
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import PopulateVariablesModal from "@/views/Documents/Create/PopulateVariablesModal";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "DocumentList",
  directives: {
    'b-popover': VBPopover,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    BAvatar,
    BButton,
    BBadge,
    BPagination,
    BTooltip,
    vSelect,
    BSpinner,

    PopulateVariablesModal,
    VueApexCharts
  },
  data() {
    return {
      submitting: false,
      isFilteringAreaOpen: false,
      currentPage: 1,
      perPage: 20,
      titleSearchQueryParam: "",
      codeSearchQueryParam: "",
      dateRangeQueryParam: [],
      dateRangeStartQueryParam: null,
      dateRangeEndQueryParam: null,
      documentTypeQueryParam: null,
      documentStatusQueryParam: null,
      labelSearchQueryParam: null,
      selectedTemplate: null,
      selectedWorkspace: null,
      useTemplate: false,
      options: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: ['#A9A2F6'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: '#e9ecef',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
    }
  },
  watch: {
    // currentPage: {
    //   immediate: true,
    //   handler(newValue, oldValue) {
    //     PEventBus.$emit("fetch-documents-by-paginated", newValue, this.perPage)
    //   }
    // },
    // perPage: {
    //   immediate: true,
    //   handler(newValue, oldValue) {
    //     PEventBus.$emit("fetch-documents-by-paginated", this.currentPage, newValue)
    //   }
    // }
  },
  mounted() {
  },
  props: {
    reff: String,
    isBusy: Boolean,
    documents: Array,
    newDocumentUrl: String,
    totalDocuments: Number,
    tableColumns: Array,
    perPageOptions: Array,
    sortBy: String,
    isSortDirDesc: Boolean,
    dataMeta: Object,
    resolveDocumentStatusVariantAndIcon: Function,
    resolveDocumentTypeVariant: Function,
  },

  computed: {
    ...mapState({
      templates: state => state.template.templates,
      loadingTemplates: state => state.template.loading,
      workspaces: state => state.workspaces.myWorkspaces
    }),
    getNewDocumentUrl() {
      return this.newDocumentUrl;
    },
    documentTypes() {
      return this.$store.getters['document/getDocumentTypes']
    },
    documentStatuses() {
      return this.$store.getters['document/getDocumentStatuses']
    },
    getRecentDocuments() {
      return null
    }
  },
  methods: {
    loadTemplatesForQuickAccess() {
      this.$store.dispatch('template/fetchQuickAccessList')
    },
    onTemplateSelected(template) {
      this.useTemplate = true
      this.selectedTemplate = template
      this.selectWorkspace()
    },
    onRowSelected(row) {
      this.$router.replace(`/documents/${row[0].id}/editor`)
    },
    getFromNow(date) {
      return DateUtil.fromNow(date);
    },
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
    filterByDate(event) {
      if (event.length > 0) {
        this.$emit('toggle-filter', true)
        this.dateRangeStartQueryParam = event[0]
        this.dateRangeEndQueryParam = event[1]

        this.updateDocumentQuery()
      }
    },
    handleFormInput(event){
      if (event === "") {
        this.updateDocumentQuery()
      }
    },
    updateDocumentQuery() {
      this.$emit('toggle-filter', true)
      const payload = {
        code: this.codeSearchQueryParam,
        documentTitle: this.titleSearchQueryParam,
        paging: {
          page: this.currentPage,
          size: this.perPage
        }
      }

      if (this.labelSearchQueryParam !== null && this.labelSearchQueryParam !== "") {
        payload.labels = [
          this.labelSearchQueryParam
        ]
      }

      if (this.documentStatusQueryParam !== null) {
        payload.documentStatuses = [
          this.documentStatusQueryParam.name
        ]
      }

      if (this.documentTypeQueryParam !== null) {
        payload.documentTypes = [
          this.documentTypeQueryParam.name
        ]
      }

      if (this.dateRangeStartQueryParam !== null || this.dateRangeEndQueryParam !== null) {
        payload.createdAt = {
          start: DateUtil.getFormattedDate2(this.dateRangeStartQueryParam),
          end: DateUtil.getFormattedDate2(this.dateRangeEndQueryParam)
        }
      }

      this.$store.dispatch('document/queryDocuments', payload)
    },
    cleanFilterParameters() {
      this.currentPage = 1
      this.perPage = 20
      this.titleSearchQueryParam = ""
      this.codeSearchQueryParam = ""
      this.documentTypeQueryParam = null
      this.documentStatusQueryParam = null

      this.$store.dispatch('document/loadMyDocuments')
    },
    handlePageUpdate(page) {
      this.currentPage = page
      this.updateDocumentQuery()
    },
    modalClosed() {
      this.selectedTemplateId = null
    }
  }
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}


.no-mode-fade-enter-active, .no-mode-fade-leave-active {
  transition: opacity .5s
}

.no-mode-fade-enter-from, .no-mode-fade-leave-to {
  opacity: 0
}

.table-resize {
	@media screen and (max-width: 900px) {
		overflow: auto;
		.documents-table {
			min-width: 1000px;
		}
	}
}

.documents-table {
	tbody tr td {
		span {
			@media screen and (max-width: 1550px) {
				font-size: 14px;
			}
		}
		.badge {
			font-size: 12px;
		}
	}
}

.hide-mobile {
	@media screen and (max-width: 900px) {
		display: none !important;
	}
}
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
